/**
 * --------------------------------------------------------------------------
 * Basic Modules: SlickCarousel.js
 * --------------------------------------------------------------------------
 */

import Util from "../Util";

/**
 * ------------------------------------------------------------------------
 * Constants
 * ------------------------------------------------------------------------
 */

const DEBUG             = false;
const NAME              = 'slickCarousel';
const DATA_KEY          = NAME;
const EVENT_KEY         = `.${DATA_KEY}`;
const LOG_KEY           = `[${NAME}]`;

const AUTOPLAY_STATE_PLAYING  = 'playing';
const AUTOPLAY_STATE_PAUSED   = 'paused';
const AUTOPLAY_ACTION_START   = 'start';
const AUTOPLAY_ACTION_STOP    = 'stop';

const SlickSettings = {
    accessibility  : true,
    adaptiveHeight : false,
    autoplay       : false,
    autoplaySpeed  : 3000,
    arrows         : true,
    centerMode     : false,
    centerPadding  : '50px',
    cssEase        : 'ease',
    dots           : false,
    draggable      : true,
    fade           : false,
    focusOnSelect  : false,
    easing         : 'linear',
    edgeFriction   : 0.15,
    infinite       : true,
    initialSlide   : 0,
    mobileFirst    : false,
    pauseOnFocus   : true,
    pauseOnHover   : true,
    pauseOnDotsHover : false,
    rows           : 1,
    slidesPerRow   : 1,
    slidesToShow   : 1,
    slidesToScroll : 1,
    speed          : 300,
    swipe          : true,
    swipeToSlide   : false,
    touchMove      : true,
    touchThreshold : 5,
    useCSS         : true,
    useTransform   : true,
    variableWidth  : false,
    vertical       : false,
    verticalSwiping : false,
    zIndex         : 500,
};

const Default = {
    play: true,
};

const Event = {
    RESIZE              : `resize${EVENT_KEY}`,
    SCROLL              : `scroll${EVENT_KEY}`,
    CLICK               : `click${EVENT_KEY}`,
};

const ClassName = {
    PLAY_PAUSE          : 'slick-playpause',
    IS_PLAYING          : '-is-playing',
    IS_PAUSED           : '-is-paused',
};

const Selector = {
    TOGGLE              : '[data-slick-carousel]',
    ANIMATION           : '[data-animation]',
    PLAY_PAUSE          : '.slick-playpause',
};

const DataAttr = {
    ATTR_ACTION : 'data-action',
    KEY_ANIMATION: 'animation',
};

class SlickCarousel {

    constructor(element, config) {

        DEBUG && console.log(LOG_KEY, 'Init');

        this._element     = element;
        this._config      = this._getConfig(config);
        this._slickConfig = this._getSlickConfig(this._config);

        this._playButton = null;
        this._autoplayState   = this._config.autoplay ?  AUTOPLAY_STATE_PLAYING : AUTOPLAY_STATE_PAUSED;

        this._setup();
    }

    destroy() {
        console.log(LOG_KEY, 'Removing slick carousel');
        if (this._config.play) {
            $(this._element).find(Selector.PLAY_PAUSE).remove();
        }
        $(this._element).slick('unslick');
        $(this._element).off(EVENT_KEY);
        $.removeData(this._element, DATA_KEY);
        this._element = null;
    }

    // Private

    _setup() {

        let firstAnimatingElems = $(this._element).find('.first').find(Selector.ANIMATION);

        $(this._element).on('init', () => {
            if (this._config.play) {
                this._playButton = $('<button></button>')
                    .addClass(ClassName.PLAY_PAUSE)
                    .attr(DataAttr.ATTR_ACTION, this._autoplayState === AUTOPLAY_STATE_PAUSED ? AUTOPLAY_ACTION_START : AUTOPLAY_ACTION_STOP)
                    .addClass(this._autoplayState === AUTOPLAY_STATE_PAUSED ? ClassName.IS_PAUSED : ClassName.IS_PLAYING)
                    .appendTo($(this._element));
            }
        });

        $(this._element).slick(this._slickConfig);

        // Apply the animation to the first item using the doAnimations() function
        this._doAnimations(firstAnimatingElems);

        $(this._element).on('beforeChange', (evt, slick, currentSlideNr, nextSlideNr) => {
            // Select the elements to be animated inside the active slide
            let animatingElems = $(slick.$slides[nextSlideNr]).find(Selector.ANIMATION);
            this._doAnimations(animatingElems);
        });

        $(this._playButton).on(Event.CLICK, () => {
            this._playPauseCarousel();
        });

        $(this._element).on('mouseover', () => {
            $(this._element).addClass('-is-hover');
        });
        $(this._element).on('mouseout', () => {
            $(this._element).removeClass('-is-hover');
        });
    }

    /**
     * Carousel autoplay toggle
     * @private
     */
    _playPauseCarousel() {

        if (this._autoplayState === AUTOPLAY_STATE_PAUSED) {
            this._startCarousel();
        }
        else {
            this._pauseCarousel();
        }
    }

    /**
     * Method to start auto playing
     * @private
     */
    _startCarousel() {
        // start playing
        $(this._element).slick('slickPlay');
        $(this._playButton)
            .attr(DataAttr.ATTR_ACTION, AUTOPLAY_ACTION_STOP)
            .toggleClass(ClassName.IS_PLAYING + ' ' + ClassName.IS_PAUSED)
        ;
        this._autoplayState = AUTOPLAY_STATE_PLAYING;
    }

    /**
     * Pause the auto play
     * @private
     */
    _pauseCarousel() {
        // pause
        $(this._element).slick('slickPause');
        $(this._playButton)
            .attr(DataAttr.ATTR_ACTION, AUTOPLAY_ACTION_START)
            .toggleClass(ClassName.IS_PLAYING + ' ' + ClassName.IS_PAUSED)
        ;
        this._autoplayState = AUTOPLAY_STATE_PAUSED;
    }

    _doAnimations(elements) {

        let animEndEv = Util.whichAnimationEvent();

        elements.each(function(idx, elem) {
            let animationType = $(elem).data(DataAttr.KEY_ANIMATION);

            // Add animate.css classes to the elements to be animated
            // Remove animate.css classes once the animation event has ended
            $(elem).addClass(animationType).one(animEndEv, () => {
                $(elem).removeClass(animationType);
            });
        });
    }

    _getConfig(config) {
        return {
            ...Default,
            ...config
        };
    }

    _getSlickConfig(config) {

        // step through the config options, and check if they are present in the default slick settings,
        // and only use them if the new settings differ from the default ones
        let slickSettings = {};
        const entries = Object.entries(config);
        for (const [property, value] of entries) {
            if (SlickSettings[property] !== undefined && SlickSettings[property] !== value) {
                slickSettings[property] = value;
            }
        }

        return slickSettings;
    }

    // Static

    /**
     * Convenient static method to initiate the module.
     *
     * @param config
     * @param selector
     */
    static bind (config, selector) {
        SlickCarousel._jQueryInterface.call($(selector || Selector.TOGGLE), config);
    }

    /**
     * jQuery Interface
     * @param config
     * @returns {*}
     */
    static _jQueryInterface(config) {
        return this.each(function() {
            const $this = $(this);
            let data    = $this.data(DATA_KEY);
            let _config = {
                ...Default,
                ...$this.data(),
                ...typeof config === 'object' && config ? config : {}
            };

            if (!data) {
                data = new SlickCarousel(this, _config);
                $this.data(DATA_KEY, data);
            }

            if (typeof config === 'string') {
                if (typeof data[config] === 'undefined') {
                    throw new TypeError(`No method named "${config}"`);
                }
                data[config]();
            }
        });
    }
}

/**
 * ------------------------------------------------------------------------
 * jQuery
 * ------------------------------------------------------------------------
 */

$.fn[NAME]             = SlickCarousel._jQueryInterface;
$.fn[NAME].Constructor = SlickCarousel;

export default SlickCarousel;
