
'use strict';

// Save bytes in the minified (but not gzipped) version:
let ArrayProto = Array.prototype, ObjProto = Object.prototype, FuncProto = Function.prototype;

// Create quick reference variables for speed access to core prototypes.
let
    push             = ArrayProto.push,
    slice            = ArrayProto.slice,
    concat           = ArrayProto.concat,
    toString         = ObjProto.toString,
    hasOwnProperty   = ObjProto.hasOwnProperty;

const Util = {

    $pageWrapper: $('c-page-wrapper'),

    // Breakpoints
    breakPoints: {
        'xs' : 0,
        'sm' : 576,
        'md' : 768,
        'lg' : 992,
        'xl' : 1200,
    },

    currentBreakPoint: 'xs',

    // viewport
    viewport:'mobile',

    setScreenType() {

        const getViewport = function() {
            Util.viewport = window.getComputedStyle(document.body, ":after")
                .getPropertyValue('content')
                .replace(/[^0-9a-zA-Z]/g, "") || "mobile";
        };

        window.attachEvent ? window.attachEvent("onresize", function() {
            getViewport();
        }) : window.addEventListener && window.addEventListener("resize", function() {
            getViewport();
        }, true);
        getViewport();
    },

    getBreakpoint(key) {
        if (this.breakPoints[key] !== undefined) {
            return this.breakPoints[key];
        }

        return 0;
    },

    listeners: [],

    /**
     * Add a listener to the object like this:
     *
     * ```
     * Util.addListener('resize', window, this.windowResizeListener = event => this.handleWindowResize(event));
     * Util.removeListener('resize', window, this.windowResizeListener);
     * ```
     *
     * @param evt
     * @param elem
     * @param fn
     */
    addListener(evt,elem,fn) {
        var callback = function callback(evt) {
            fn(evt,evt&&evt.target?evt.target:window.event.srcElement);
        };

        Util.listeners.push({
            evt:evt,
            elem:elem,
            fn:fn,
            callback:callback,
            removed:false
        });

        if (elem.addEventListener) {
            elem.addEventListener(evt, callback);
        }
        else if (elem.attachEvent) {
            elem.attachEvent('on' + evt,callback);
        }
        else {
            elem['on' + evt] = callback;
        }
    },

    removeListener(evt, elem, fn) {
        var callback;
        this.listeners.forEach(function(evtObj) {

            if (evtObj.evt === evt && evtObj.elem === elem && evtObj.fn === fn && !evtObj.removed) {
                evtObj.removed = true;
                callback = evtObj.callback;

                if (elem.removeEventListener) {
                    elem.removeEventListener(evt, callback);
                }
                else if (elem.detachEvent) {
                    elem.detachEvent('on' + evt, callback);
                }
                else {
                    delete elem['on' + evt];
                }

                return false;
            }
        });

        console.log(this.listeners);
    },

    /**
     * Init touch support test
     */
    hasTouchSupport: 'ontouchstart' in window||navigator.maxTouchPoints>0||navigator.msMaxTouchPoints>0,
    touchClass:'touch-device',
    noTouchClass:'no-touch-device',

    initFeatureTestClasses(){
        document.body.classList.add(this.hasTouchSupport?this.touchClass:this.noTouchClass);
        if(navigator.userAgent.indexOf('MSIE')>=0){
            document.body.classList.add('-ie10');
        }
    },

    isEditMode() {
        return !!document.querySelector('#ccm-toolbar');
    },

    /**
     * Detect CSS animation event
     * @returns {*}
     */
    whichAnimationEvent() {

        let t,
            el = document.createElement("fakeelement");

        let animations = {
            "animation"      : "animationend",
            "OAnimation"     : "oAnimationEnd",
            "MozAnimation"   : "animationend",
            "WebkitAnimation": "webkitAnimationEnd"
        };

        for (t in animations){
            if (el.style[t] !== undefined){
                return animations[t];
            }
        }
    },

    // Delays a function for the given number of milliseconds, and then calls
    // it with the arguments supplied.
    delay(func, wait) {
        let args = slice.call(arguments, 2);
        return setTimeout(function(){ return func.apply(null, args); }, wait);
    },

    defer(func) {
        return this.delay.apply(this, [func, 1].concat(Array.slice.call(arguments, 1)));
    },

    SlickSettings: {
        accessibility       : true,
        adaptiveHeight      : false,
        autoplay            : false,
        autoplaySpeed       : 3000,
        arrows              : true,
        centerMode          : false,
        centerPadding       : '50px',
        cssEase             : 'ease',
        dots                : false,
        draggable           : true,
        fade                : false,
        focusOnSelect       : false,
        easing              : 'linear',
        edgeFriction        : 0.15,
        infinite            : true,
        initialSlide        : 0,
        mobileFirst         : false,
        pauseOnFocus        : true,
        pauseOnHover        : true,
        pauseOnDotsHover    : false,
        rows                : 1,
        slidesPerRow        : 1,
        slidesToShow        : 1,
        slidesToScroll      : 1,
        speed               : 300,
        swipe               : true,
        swipeToSlide        : false,
        touchMove           : true,
        touchThreshold      : 5,
        useCSS              : true,
        useTransform        : true,
        variableWidth       : false,
        vertical            : false,
        verticalSwiping     : false,
        zIndex              : 500,
    },

    getSlickConfig(config) {

        // step through the config options, and check if they are present in the default slick settings,
        // and only use them if the new settings differ from the default ones
        let slickSettings = {};
        const entries = Object.entries(config);
        for (const [property, value] of entries) {
            if (this.SlickSettings[property] !== undefined && this.SlickSettings[property] !== value) {
                slickSettings[property] = value;
            }
        }

        return slickSettings;
    },

    init() {
        this.setScreenType();
        this.initFeatureTestClasses();
    }
};
// Initialize Util script

//Util.init();

// Export as module
export default Util;
