/**
 * --------------------------------------------------------------------------
 * Quodari Module: StickyHeader.js
 * --------------------------------------------------------------------------
 */

import Util from '../../../basic_theme/resources/js/modules/Util';

/*
 * Constants
 */
const DEBUG             = false;
const NAME              = 'stickyHeader';
const DATA_KEY          = NAME;
const EVENT_KEY         = `.${DATA_KEY}`;
const LOG_KEY           = `[${NAME}]`;

const Default = {
    stickyPosXs         : null,
    stickyPosSm         : null,
    stickyPosMd         : null,
    stickyPosLg         : null,
    animationSpeed      : 400,
    cssStickyHeaderOn   : 'sticky-header-on',
    scrollSensitivity   : 5,
};

const Event = {
    RESIZE              : `resize${EVENT_KEY} orientationchange${EVENT_KEY}`,
    SCROLL              : `scroll${EVENT_KEY}`,
    CLICK               : `click${EVENT_KEY}`,
};

const ClassName = {
    MOBILE_MENU_ACTIVE  : 'mobile-menu-active',
    ANIMATION_SHOW      : 'is-menu-down',
    ANIMATION_HIDE      : 'is-menu-up',
};

const Selector = {
    TOGGLE              : '[data-toggle="sticky-header"]',
    CONTEXT             : '.ccm-page',
    WRAPPER             : '.header-section',
};

class StickyHeader {

    constructor(element, config) {

        DEBUG && console.log(LOG_KEY, 'Init');

        if (Util.isEditMode()) {
            return;
        }

        this._lastScrollTop = 0;
        this._stickyActive = false;
        this._stickyVisible = false;

        this._element = element;
        this._config  = this._getConfig(config);

        this._context = document.querySelector(Selector.CONTEXT);
        this._wrapper = document.querySelector(Selector.WRAPPER);
        this._headerHeight = this._calcHeaderHeight();

        this._addEventListeners();
    }

    destroy() {
        $(this._element).off(EVENT_KEY);
        $.removeData(this._element, DATA_KEY);
        this._element = null;
    }

    // Private

    _calcHeaderHeight() {
        return $(this._element).outerHeight();
    }

    _getConfig(config) {
        return {
            ...Default,
            ...config
        };
    }

    _addEventListeners() {
        $(window).on(Event.RESIZE, (event) => this._updateHeader(event));
        $(window).on(Event.SCROLL, (event) => this._handleScroll(event));
    }

    _handleScroll() {

        let $this = $(window),
            lastScrollTop = this._lastScrollTop,
            scrollTop = $this.scrollTop(),
            width = $this.width(),
            scrollPoint = (null !== this._getScrollPoint()) ? this._getScrollPoint() : this._headerHeight;

        this._lastScrollTop = scrollTop;
        scrollPoint = scrollPoint * 3;

        // First check
        if (width < 0) {
            return;
        }

        // Handle the activation of the sticky header
        if (scrollTop > scrollPoint) {
            this._stickyHeaderOn();
        }
        else {
            this._stickyHeaderOff();
            return;
        }

        if (scrollTop > lastScrollTop) {
            this._hide();
        }
        else {
            // Scroll Up
            // If did not scroll past the document (possible on mac)...

            // If current position > last position AND scrolled past scroll point
            if ((lastScrollTop - scrollTop) <= this._config.scrollSensitivity) {
                return;
            }
            if(scrollTop + $(window).height() < $(document).height()) {
                this._show();
            }
        }
    }

    _stickyHeaderOn() {
        if (! this._stickyActive) {
            this._context.classList.add(this._config.cssStickyHeaderOn);
            $(this._wrapper).css('paddingTop', `${this._headerHeight}px`);
            $(this._element).addClass('is-animated');
            this._stickyActive = true;
        }
    }

    _stickyHeaderOff() {
        if (this._stickyActive) {
            this._hide();
            setTimeout(() =>  {
                $(this._wrapper).css('paddingTop', 0);
                this._context.classList.remove(this._config.cssStickyHeaderOn);
                this._stickyActive = false;
            }, 100);
        }
    }

    _show() {
        if (this._stickyActive && ! this._stickyVisible) {

            $(this._element).addClass('is-animated');
            $(this._element).addClass(ClassName.ANIMATION_SHOW);
            this._stickyVisible = true;
        }
    }

    _hide() {
        if (this._stickyActive && this._stickyVisible) {

            // First hide the menu
            $(this._element).addClass(ClassName.ANIMATION_HIDE);
            setTimeout(() => {
                $(this._element).removeClass('is-animated');
                $(this._element).removeClass(ClassName.ANIMATION_SHOW);
                $(this._element).removeClass(ClassName.ANIMATION_HIDE);
                this._stickyVisible = false;
            }, this._config.animationSpeed);
        }
    }

    _updateHeader(event) {

        let $this = $(event.currentTarget),
            scrollPoint;

        scrollPoint = (null !== this._getScrollPoint()) ? this._getScrollPoint() : this._headerHeight;
        scrollPoint = scrollPoint - 5;

        this._headerHeight = this._calcHeaderHeight();

        if ($this.scrollTop() < scrollPoint) {
            this._headerHeight = this._calcHeaderHeight();
        }
    }

    /**
     * Check if any responsive scroll points have been defined.
     *
     * @returns {boolean}
     * @private
     */
    _hasScrollPoints() {
        return this._config.stickyPosXs !== null ||
            this._config.stickyPosSm !== null ||
            this._config.stickyPosMd !== null ||
            this._config.stickyPosLg !== null;
    }

    /**
     * Build list of responsive scroll points.
     *
     * @returns {{}}
     * @private
     */
    _buildScrollPoints() {

        let scrollPoints = {};

        if (null !== this._config.stickyPosXs) {
            scrollPoints.xs = this._config.stickyPosXs;
        }

        if (null !== this._config.stickyPosSm) {
            scrollPoints.sm = this._config.stickyPosSm;
        }

        if (null !== this._config.stickyPosMd) {
            scrollPoints.md = this._config.stickyPosMd;
        }

        if (null !== this._config.stickyPosLg) {
            scrollPoints.lg = this._config.stickyPosLg;
        }

        return scrollPoints;
    }

    _getScrollPoint() {

        if (! this._hasScrollPoints()) {
            return null;
        }

        let breakPoints = Util.breakPoints,
            scrollPoints = this._buildScrollPoints(),
            currentBreakPoint = Util.currentBreakPoint,
            scrollPoint,
            value = 0;

        for (let breakPoint in breakPoints) {
            if (! breakPoints.hasOwnProperty(breakPoint)) continue;

            if (scrollPoints.hasOwnProperty(breakPoint)) {
                value = scrollPoints[breakPoint];
            }

            scrollPoint = value;

            if (breakPoint === currentBreakPoint) {
                break;
            }
        }

        return scrollPoint;
    }


    // Static

    /**
     * Convenient static method to initiate the module.
     *
     * @param config
     * @param selector
     */
    static bind (config, selector) {
        StickyHeader._jQueryInterface.call($(selector || Selector.TOGGLE), config);
    }

    /**
     * jQuery Interface
     * @param config
     * @returns {*}
     */
    static _jQueryInterface(config) {
        return this.each(function() {
            const $this = $(this);
            let data    = $this.data(DATA_KEY);
            let _config = {
                ...Default,
                ...$this.data(),
                ...typeof config === 'object' && config ? config : {}
            };

            if (!data) {
                data = new StickyHeader(this, _config);
                $this.data(DATA_KEY, data);
            }

            if (typeof config === 'string') {
                if (typeof data[config] === 'undefined') {
                    throw new TypeError(`No method named "${config}"`);
                }
                data[config]();
            }
        });
    }
}

/**
 * ------------------------------------------------------------------------
 * jQuery
 * ------------------------------------------------------------------------
 */

$.fn[NAME]             = StickyHeader._jQueryInterface;
$.fn[NAME].Constructor = StickyHeader;

export default StickyHeader;
