/**
 * --------------------------------------------------------------------------
 * Basic Modules: Accordion.js
 * --------------------------------------------------------------------------
 */

import Util from "./Util";

/*
 * Constants
 */
const DEBUG             = false;
const NAME              = 'accordion';
const DATA_KEY          = NAME;
const EVENT_KEY         = `.${DATA_KEY}`;
const LOG_KEY           = `[${NAME}]`;

const Default = {
    collapsible: true,
    scrollOffset: 100,
};

const Event = {
    RESIZE              : `resize${EVENT_KEY} orientationchange${EVENT_KEY}`,
    SCROLL              : `scroll${EVENT_KEY}`,
    CLICK               : `click${EVENT_KEY}`,
};

const ClassName = {
    ACTIVE              : 'active',
};

const Selector = {
    TOGGLE              : '[data-toggle-accordion]',
    TAB                 : '.accordion-tab'
};

const DataAttr = {
};

class Accordion {

    constructor(element, config) {

        DEBUG && console.log(LOG_KEY, '::constructor() invoked');

        this._element = element;
        this._config  = this._getConfig(config);

        this._addEventListeners();
    }

    destroy() {
        $(this._element).off(EVENT_KEY);
        $.removeData(this._element, DATA_KEY);
        this._element = null;
    }

    // Private

    _addEventListeners() {
        $(Selector.TOGGLE).find(Selector.TAB).on(Event.CLICK, (event) => this._handleAccordionTabClick(event));
    }

    _handleAccordionTabClick(event) {

        let $this = $(event.currentTarget);
        let isActive = $this.hasClass(ClassName.ACTIVE);

        $(Selector.TOGGLE).find(Selector.TAB).each(function(){
            $(this).parent().removeClass(ClassName.ACTIVE);
            $(this).removeClass(ClassName.ACTIVE);
        })

        if (! this._config.collapsible || ! isActive) {
            $this.parent().addClass(ClassName.ACTIVE);
            $this.addClass(ClassName.ACTIVE);
            this._scrollIntoView($this.parent());
        }
    }

    _scrollIntoView(elem) {
        if (!this._isInView(elem)) {
            let posY = elem[0].getBoundingClientRect().top - document.body.getBoundingClientRect().top;
            window.scrollTo(0, posY - this._config.scrollOffset);
        }
    }

    _isInView(elem) {
        let top = elem[0].getBoundingClientRect().top;
        return top >= 0;
    }

    _getConfig(config) {
        return {
            ...Default,
            ...config
        };
    }

    // Static

    /**
     * Convenient static method to initiate the module.
     *
     * @param config
     * @param selector
     */
    static bind (config, selector) {
        Accordion._jQueryInterface.call($(selector || Selector.TOGGLE), config);
    }

    /**
     * jQuery Interface
     * @param config
     * @returns {*}
     */
    static _jQueryInterface(config) {
        return this.each(function() {
            const $this = $(this);
            let data    = $this.data(DATA_KEY);
            let _config = {
                ...Default,
                ...$this.data(),
                ...typeof config === 'object' && config ? config : {}
            };

            if (!data) {
                data = new Accordion(this, _config);
                $this.data(DATA_KEY, data);
            }

            if (typeof config === 'string') {
                if (typeof data[config] === 'undefined') {
                    throw new TypeError(`No method named "${config}"`);
                }
                data[config]();
            }
        });
    }
}

/**
 * ------------------------------------------------------------------------
 * jQuery
 * ------------------------------------------------------------------------
 */

$.fn[NAME]             = Accordion._jQueryInterface;
$.fn[NAME].Constructor = Accordion;

export default Accordion;
