//import BootstrapCarousel from "../../../basic_theme/resources/js/modules/Carousel/BootstrapCarousel";
//import ProductSlider from "../../../basic_theme/resources/js/modules/Store/ProductSlider";
//import BackToTop from "../../../basic_theme/resources/js/modules/BackToTop";
// import Clickable from "../../../basic_theme/resources/js/modules/Clickable";
// import SearchPopover from "../../../basic_theme/resources/js/modules/SearchPopover";
// import MainMenu from "../../../basic_theme/resources/js/modules/MainMenu";
// import MobileMenu from "../../../basic_theme/resources/js/modules/MobileMenu";
// import MobileMenuToggle from "../../../basic_theme/resources/js/modules/MobileMenuToggle";
// import BreakPoints from "../../../basic_theme/resources/js/modules/BreakPoints";
// import StickyHeader from "../../../basic_theme/resources/js/modules/StickyHeader";
//import Accordion from "../../../basic_theme/resources/js/modules/Accordion";
// import ProductFilter from "../../../basic_theme/resources/js/modules/Store/ProductFilter";
// import ProductGallery from "../../../basic_theme/resources/js/modules/Store/ProductGallery";
// import SearchBar from "../../../basic_theme/resources/js/modules/SearchBar";
// import GalleryIsotope from "../../../basic_theme/resources/js/modules/Gallery/Isotope";
// import TinySlider from "../../../basic_theme/resources/js/modules/Carousel/TinySlider";
// import LayoutSections from "../../../basic_theme/resources/js/modules/LayoutSections";
// import GridMasonry from "../../../basic_theme/resources/js/modules/Grid/GridIsotope";
// import TinyProductSlider from "../../../basic_theme/resources/js/modules/Store/TinyProductSlider";
import SlickCarousel from "../../../basic_theme/resources/js/modules/Carousel/SlickCarousel";

//import SmoothScroll from '../../../basic_theme/node_modules/smoothscroll-polyfill/dist/smoothscroll';
import Accordion from "../../../basic_theme/resources/js/modules/Accordion";
import StickyHeader from "./sticky-header";

jQuery(function(){
    //BreakPoints.init();
    //LayoutSections.bind();
    //BootstrapCarousel.bind();
    //TinySlider.bind();
    SlickCarousel.bind();
    //GalleryIsotope.bind();
    //ProductSlider.bind();
    //TinyProductSlider.bind();
    //ProductGallery.bind();
    //BackToTop.bind();
    //Clickable.bind();
    //SearchPopover.bind();
    //SearchBar.bind();
    //MainMenu.bind();
    //MobileMenuToggle.init();
    //MobileMenu.init();
    //StickyHeader.bind();
    //ProductFilter.bind();
    //GridMasonry.bind();
    // Initialize smooth scroll polyfill
    //SmoothScroll.polyfill();
    Accordion.bind({ collapsible: true });
    StickyHeader.bind({
        animate : true,
    });
});
